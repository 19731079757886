<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card title="Les ventes pour chaque point de vente">
          <vue-good-table-vue
            :columns="columns"
            :rows="rows"
          />
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Visualisation des ventes mensuelles">
          <ColumnChart3D
            :data="chartData"
            category-field="month"
            value-field="sales"
            series-name="Ventes"
            x-axis-title="Mois"
            y-axis-title="Valeur (en euros)"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card title="La répartition des ventes par catégorie de produits pour chaque point de vente"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="store in stores"
        :key="store.id"
        md="4"
      >
        <b-card :title="store.name">
          <DonutChart
            :data="store.salesByCategory"
            category-field="category"
            value-field="value"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'
import ColumnChart3D from '@/views/m-components/charts/ColumnChart3D.vue'
import DonutChart from '@/views/m-components/charts/DonutChart.vue'

export default {
  name: 'MainVue',
  components: {
    DonutChart,
    ColumnChart3D,
    VueGoodTableVue,
    BRow,
    BCol,
    BCard,
  },

  data() {
    return {
      columns: [
        {
          label: 'Point de vente',
          field: 'store',
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
        },
        {
          label: 'Ventes',
          field: 'sales',
          type: 'number',
        },
        {
          label: 'Total',
          field: 'total',
          type: 'number',
        },
      ],
      rows: [
        {
          store: 'Point de vente 1',
          date: '2022-01-01',
          sales: 100,
          total: 500,
        },
        {
          store: 'Point de vente 2',
          date: '2022-01-02',
          sales: 150,
          total: 750,
        },
        {
          store: 'Point de vente 3',
          date: '2022-01-03',
          sales: 200,
          total: 1000,
        },
      ],
      chartData: [
        {
          month: 'Janvier',
          sales: 50000,
        },
        {
          month: 'Février',
          sales: 60000,
        },
        {
          month: 'Mars',
          sales: 70000,
        },
        {
          month: 'Avril',
          sales: 55000,
        },
        {
          month: 'Mai',
          sales: 80000,
        },
        {
          month: 'Juin',
          sales: 90000,
        },
        {
          month: 'Juillet',
          sales: 75000,
        },
        {
          month: 'Août',
          sales: 85000,
        },
        {
          month: 'Septembre',
          sales: 95000,
        },
        {
          month: 'Octobre',
          sales: 85000,
        },
        {
          month: 'Novembre',
          sales: 70000,
        },
        {
          month: 'Décembre',
          sales: 80000,
        },
      ],
      stores: [
        {
          id: 1,
          name: 'Point de vente 1',
          salesByCategory: [
            { category: 'Jus', value: 3500 },
            { category: 'Boissons gazeuses', value: 4500 },
            { category: 'Eau minérale', value: 2000 },
          ],
        },
        {
          id: 2,
          name: 'Point de vente 2',
          salesByCategory: [
            { category: 'Jus', value: 2500 },
            { category: 'Boissons gazeuses', value: 6000 },
            { category: 'Eau minérale', value: 1500 },
          ],
        },
        {
          id: 3,
          name: 'Point de vente 3',
          salesByCategory: [
            { category: 'Jus', value: 4500 },
            { category: 'Boissons gazeuses', value: 3000 },
            { category: 'Eau minérale', value: 2500 },
          ],
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
